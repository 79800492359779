import Commercial, {CommercialType} from "@/Atoms/Commercial";


const AdvertisementSection = ({top, className, ...props}: {top?: boolean, className?: string}) => {

  //
  return <section className={className} {...props}>
    <div className={'container flex justify-center'}>
      <Commercial type={CommercialType.Leaderboard} top={top ?? false}/>
    </div>
  </section>
}

export default AdvertisementSection;
